/*---- import Fonts ----*/

@import url("https://fonts.googleapis.com/css?family=Rajdhani:300,400,500,600,700");
@import url("https://fonts.googleapis.com/css?family=Lato:300,400,700,900&display=swap");

/*---- global ----*/

:root {
  /*--- theme colors ----*/
  --color-primary: #ff283f;
  --color-secondary: #6f0720;
  --color-dark: #430919;
  --color-black: #000000;
  --color-text: #ffffff;
  --color-text-lighter: whitesmoke;
  --color-text-grey: #666666;
}

* {
  box-sizing: border-box !important;
}

html {
  scroll-behavior: smooth;
}

body {
  background: var(--color-black);
  color: var(--color-text-grey);
  font-size: 14px;
  font-family: "Lato", sans-serif;
  line-height: 1.80857;
  font-weight: normal;
  overflow-x: hidden;
}
#root {
  overflow: hidden;
}
a {
  text-decoration: none !important;
  outline: none !important;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  letter-spacing: 0;
  font-weight: normal;
  position: relative;
  padding: 0 0 10px 0;
  font-weight: normal;
  line-height: normal;
  margin: 0;
}

h1 {
  font-size: 24px;
}

h2 {
  font-size: 22px;
}

h3 {
  font-size: 18px;
}

h4 {
  font-size: 16px;
}

h5 {
  font-size: 14px;
}

h6 {
  font-size: 13px;
}

*,
*::after,
*::before {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

h1 a,
h2 a,
h3 a,
h4 a,
h5 a,
h6 a {
  text-decoration: none !important;
  opacity: 1;
}

button:focus {
  outline: none;
}

ul,
li,
ol {
  margin: 0px;
  padding: 0px;
  list-style: none;
}

p {
  margin: 0px;
  text-align: left;
  color: whitesmoke;
  font-size: 17px;
  line-height: 30px;
  font-weight: 400;
}

a {
  text-decoration: none;
  outline: none !important;
}

a,
.btn {
  text-decoration: none !important;
  outline: none !important;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

img {
  max-width: 100%;
  height: auto;
}

:focus {
  outline: 0;
}

.form-control:focus {
  border-color: var(--color-text) !important;
  box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.25);
}

.badge {
  font-weight: 500;
}

blockquote {
  margin: 20px 0 20px;
  padding: 30px;
}

button {
  border: 0;
  margin: 0;
  padding: 0;
  cursor: pointer;
}

.full {
  float: left;
  width: 100%;
}

.full {
  width: 100%;
  float: left;
  margin: 0;
  padding: 0;
}
.spacer {
  border: none;
  height: 2px; /* Customize height as needed */
  background-color: grey; /* Customize color as needed */
  margin: 110px 10vw; /* Adjust margin as needed */
  position: relative;
}

/*---- fading ----*/
.fade-in-element { /* js fade in, set to .visible */
  opacity: 0;
  transition: opacity 1s ease-in-out;
  will-change: opacity; /* Optimization hint for the browser */
}
.fade-in-element.visible {
  opacity: 1;
}

.fadeIn{ /* automatic fade in */
  animation: fadeIn 1s ease-in forwards;
  will-change: opacity; /* Optimization hint for the browser */
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
/*---- end fading ----*/

/*---- background ----*/
#back-to-top {
  position: fixed;
  bottom: 0;
  right: 0;
  background: var(--color-dark);
  border-radius: 100%;
  box-shadow: 3px 3px 5px black;
  height: 50px;
  width: 50px;
  cursor: pointer;
  transition: all 0.5s ease; /* Smooth transition effect */
  z-index: 2;
}
#back-to-top img {
  width: 60%; /*centering hack*/
  margin: 20%;
  transition: transform 0.5s ease; /* Smooth transition effect */
}
#back-to-top:hover {
  transform: translate(2px, 2px);
  background: var(--color-secondary)
}
#cylinder1 {
  top: -400px;
  left: 500px;
  background-color: var(--color-dark);
}
#cylinder2 {
  top: 0px;
  left: 400px;
  background-color: var(--color-dark);
}
#cylinder3 {
  top: -600px;
  left: 1000px;
  background-color: var(--color-secondary);
}
.cylinder {
  position: fixed;
  z-index: -1;
  width: 70px; /* Adjust width */
  height: 600px; /* Adjust height */
  border-radius: 35px;

  transform: rotate(45deg);
  animation: move 60s linear infinite, fade-in-out 60s linear infinite;
}
@keyframes move {
  0% {
    transform: translate(0, 0) rotate(45deg);
  }
  100% {
    transform: translate(-50vw, 50vh) rotate(45deg);
  }
}

@keyframes fade-in-out {
  0% {
    opacity: 0; /* Start with opacity 0 for fade-in */
  }
  3% {
    opacity: 1; /* Quickly fade in */
  }
  95% {
    opacity: 1; /* Keep opacity at 1 for most of the animation */
  }
  100% {
    opacity: 0; /* Fade out at the end */
  }
}
/*---- end background ----*/

/*---- navigation ----*/
.navbar-nav .dropdown-menu {
  background-color: black;
  box-shadow: 4px 4px 8px 3px black;
  width: max-content;
  border-left: whitesmoke 2px solid;
  margin-left: 30px;
}
.nav-link {
  padding: 0.5rem 25px !important;
  color: #fff;
  font-size: 16px;
  line-height: 20px;
  text-transform: uppercase;
  background: none;
}

.nav-link:focus,
.nav-link:hover {
  color: #e70139;
}

.active > .nav-link,
.nav-link.active,
.nav-link.show {
  color: #e70139;
}
.hamburger {
  background-color: white;
  height: 4px;
  margin: 6px 0 6px 0;
}

.logo img {
  border-radius: 100%;
  box-shadow: 0px 0px 5px 5px var(--color-dark);
  transition: transform 0.5s ease; /* Smooth transition effect */
}
.logo img:hover {
  transform: translate(2px, 2px);
}

/*---- end navigation ----*/

/*---- banner section ----*/

.socials svg {
  width: 30px;
  height: 30px;
  color: white;
  transition: all 0.5s ease; /* Smooth transition effect */
}
.socials svg:hover {
  color: #e70139;
  transform: scale(1.1);
}
.hideletter {
  color: transparent;
  display: inline;
}
.typeletter {
  color: auto;
  animation: typeSimulate 0.3s linear forwards;
  display: inline;
}
.typecursor {
  animation: typeSimulate 1s infinite;
  display: inline;
}

@keyframes typeSimulate {
  0% {
    background-color: white;
  }
  50% {
    background-color: white;
  }
  100% {
    background-color: none;
  }
}
.bann-img-col {
  height: 200px;
}
.bann-img {
  position: relative;
  max-width: 360px;
  border-radius: 100%;
}

.bann-img figure {
  margin: 0;
  border-radius: 100%;
  border: 10px solid whitesmoke;
  transition: transform 0.5s ease; /* Smooth transition effect */
}

.bann-img figure img {
  width: 100%;
  border-radius: 100%;
  box-shadow: 0 0 8px 8px whitesmoke inset;
}

.bann-img:hover figure {
  transform: scale(1.03); /* Zoom effect on hover */
}

:root {
  --starting-degree: 0deg;
  --duration: 60s;
}

.circle-container {
  position: absolute;
  width: 85%;
  height: 85%;
  top: 7.5%; 
  left: 7.5%; 
  border-radius: 100%;
  animation: spin var(--duration) linear infinite;
  margin: auto;
  z-index: 1; /* Adjust the z-index to position the image on top */
  transition: transform 0.5s ease; /* Smooth return */
}

.bann-img:hover .circle-container, .bann-img:hover .circle-icon {
  animation-play-state: paused;
}

.circle-item {
  position: absolute;
  transform-origin: center;
  width: 100%; 
  height: 100%;
  border-radius: 100%;
  user-select: none;
  z-index: 0; /* Ensure circle-item is below circle-icon */
  pointer-events: none; /* Ensure circle-item does not block pointer events */

}

.circle-icon {
  position: absolute;
  background: var(--color-dark);
  border-radius: 100%;
  box-shadow: 3px 3px 5px black;
  height: 60px;
  width: 60px;
  /*cursor: pointer; add click to scroll later */
  transition: all 0.5s ease; /* Smooth transition effect */
  bottom: 0;
  left: 0;
  animation: counterspin var(--duration) infinite linear;
  z-index:2;
  pointer-events: auto; /* Ensure circle-icon can receive pointer events */

}
.circle-icon:hover {
  transform: scale(1.03);
}

.circle-icon svg{
  width: 50%;
  height: 50%;
  margin: 25%;
  color: white;
}

@keyframes counterspin {
  from {
    transform: rotate(var(--starting-degree));
  }
  to {
    transform: rotate(calc(var(--starting-degree) - 360deg));
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.big-button {
  border-radius: 0.5rem;
  font-size: 20px;
  background-color: #fff;
  color: var(--color-primary);
  font-weight: 600;
  padding: 22px 0px;
  width: 100%;
  max-width: 320px;
  text-align: center;
  display: inline-block;
  transition: ease all 0.5s;
  text-transform: uppercase;
}
.big-button svg {
  width: 24px;
  height: 24px;
  vertical-align: text-bottom;
}

.big-button:hover {
  background-color: var(--color-primary);
  color: #fff;
  cursor: pointer;
}
h1.white {
  color: #fff;
  font-size: 78px;
  line-height: 96px;
  font-weight: 800;
  text-align: center;
}

h2.white {
  font-size: 43px;
  color: white;
  line-height: 55px;
  font-weight: 800;
  padding: 0;
  text-align: center;
}
h3.white {
  text-align: left;
  font-size: 45px;
  color: white;
  line-height: 55px;
  font-weight: 800;
  padding: 0;
}
.hero-text {
  text-align: center;
}
.container {
  max-width: 1170px;
}

/*---- end banner section ----*/

/*---- work section ----*/
.work-col h2, .work-col h3 {
  font-size: 21px;
  color: #b3b3b3;
}

.work_link,
.work_link h3 {
  display: inline-block;
  color: #e70139;
  transition: ease-in all 0.2s;
}
.work_link:hover,
.work_link h3:hover {
  color: whitesmoke;
}

.work_pic {
  position: relative;
  margin-bottom: 20px;
}

.work_pic img {
  transition: ease-in all 0.2s;
  width: 100%;
  border-radius: 0.5rem;
}

.work_pic:hover img {
  transform: scale(0.99);
}
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Semi-transparent black background */
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.work_pic:hover .overlay {
  opacity: 1;
}

.overlay button {
  color: var(--color-primary); 
  background: white; 
  padding: 10px 20px;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
  text-transform: uppercase;
  border-radius: 5px;
  transition: all 0.5s ease;
}

.overlay button:hover {
  color: white; 
  background: var(--color-primary); 
}

.work-col {
  display: grid;
  margin-bottom: 30px;
}
.tech_bubble{
  padding:0 10px 0 10px;
  margin: 0 10px 10px 0;
  background-color: var(--color-dark);
  border-radius: 0.5rem;
  color: whitesmoke;
  font-size: 17px;
  line-height: 30px;
  font-weight: 400;
  box-shadow: 3px 3px 5px black;
}
.github_bubble{
  padding:7px;
  width:45px;
  height: 45px;
  background-color: var(--color-dark);
  box-shadow: 3px 3px 5px black;
  border-radius: 100%;
}
.github_bubble:hover{
  background-color: var(--color-secondary);
  transform: translate(1.5px, 1.5px);
}
.github_bubble svg{
  color: whitesmoke;
  width:30px;
  height: 30px;
}
/*---- end work section ----*/

/*---- contact ----*/
.contact-links {
  background: #fff;
  padding: 31px;
  text-align: left;
  border-radius: 0.5rem;
}

.contact-icons {
  margin-right: 20px;
  width: 40px;
}

.contact-links a {
  color: #e70139;
}

.contact-links ul li {
  padding-bottom: 25px;
  font-size: 25px;
  color: #e70139;
  line-height: 30px;
}

.contact-links ul li:last-child {
  padding-bottom: 0;
}

.contact-links a:hover {
  color: #000;
}
/*---- end contact section ----*/

/*---- footer ----*/
.copyright {
  background: var(--color-secondary);
  margin-top: 90px;
  position: relative;
}

.copyright p {
  color: #fff;
  font-size: 17px;
  line-height: 22px;
  font-weight: 400;
}

.copyright a {
  color: #fff;
  text-decoration: underline !important;
}

.copyright a:hover {
  color: #000;
}
/*---- end footer ----*/

/*---- comment writer ----*/
.factorGroup,#studentInfoDiv,#generateDiv{
  background:#e8e9ee;
}
.input-group-text{
  background:#CAD6E2;
  height: 100%;
}
.infoBubble{
  width:20px;
}

@media (min-width: 768px) {
  .sticky-column {
      position: sticky;
      top: 0;
  }
}
/*---- end comment writer ----*/

/*---- Responsive Breakpoints ----*/

/*---- xl-breakpoint ----*/
@media (min-width: 1200px){
  
}

/*---- lg-breakpoint ----*/
@media (min-width: 992px) and (max-width: 1199px) {
  
}

/*---- md-breakpoint ----*/
@media (min-width: 768px) and (max-width: 991px) {
  .bann-img-col {
    height: auto;
  }
  h2.white {
    font-size: 38px;
    line-height: 41px;
  }
}

/*---- sm-breakpoint ----*/
@media (min-width: 576px) and (max-width: 767px) {
  .bann-img-col {
    height: auto;
  }
  h2.white {
    font-size: 30px;
    line-height: 41px;
  }
}

/*---- extra small (no breakpoint) ----*/
@media (max-width: 575px) {
  .bann-img-col {
    height: auto;
  }
  .header {
    padding: 30px 0px;
  }
  .big-button {
    max-width: 250px;
  }
  h1.white {
    font-size: 56px;
    line-height: 81px;
  }
  h2.white {
    font-size: 30px;
    line-height: 41px;
  }
  .hero-text{
    font-size:15px;
  }
  .contact-links ul li {
    font-size: 18px;
    line-height: 25px;
  }
  .contact-icons {
    width: 25px;
    margin-right: 10px;
  }
  .socials svg {
    width: 25px;
    height: 25px;
  }
  .spacer {
    margin: 70px 10vw;
  }
  .work-col h2, .work-col h3 {
    font-size: 20px;
  }
  .github_bubble{
    padding:5px;
    width:35px;
    height: 35px;
  }
  .github_bubble svg{
    width:25px;
    height: 25px;
  }
  #back-to-top {
    height: 35px;
    width: 35px;
  }
}
